import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import CreateEditProjectTask from "../CreateEditProjectTask";
import { Breadcrumbs, Typography } from "@mui/material";

//This class displays lists of tasks

export async function loadData(offset, projectId) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/tasks/?offset=${offset}&limit=10`
    if (projectId) {
        url = `${process.env.REACT_APP_API_URL}/tasks/?offset=${offset}&limit=10&project_id=${projectId}`
    }
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function deleteData(id) {
    var body;
    var url = `${process.env.REACT_APP_API_URL}/tasks/${id}`;
    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function TaskList({ showBreadcrumb, projectId }) {

    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    const [loading, setLoading] = useState(false);

    const [taskListData, setTaskListData] = useState(null);

    const [openCreateDialog, setOpenCreateDialog] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [selected, setSelected] = useState([]);
    const [offset, setOffset] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsCount, setRowsCount] = useState(null);

    const [project, setProject] = useState(null);

    const handleClose = () => {
        setOpenCreateDialog(false);
        setOpenDeleteDialog(false);
        setEditObj(null);
    }

    useEffect(() => {

    }, [pageNo, offset])

    async function deleteSelectedRow() {
        if (rowsData?.length > 0) {
            var filteredArray = [...rowsData]
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`${response}`);
                }
                //setErrMsg(``)
                filteredArray = [...filteredArray.filter((row) => row.id !== selected[x])]
            }
            setRowsData(filteredArray);
        }

    };

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const getData = useCallback(async (params) => {
        setLoading(true)
        var response;
        if (params) {
            setPageNo(pageNo + params)
            setOffset((pageNo + params) * 10);
            if (projectId) {
                response = await loadData((pageNo + params) * 10, projectId);
            } else {
                response = await loadData((pageNo + params) * 10);
            }

        } else {
            setPageNo(0)
            setOffset(0)
            if (projectId) {
                response = await loadData(0, projectId);
            } else {
                response = await loadData(0);
            }
        }

        if (!response?.data) {
            setErrMsg(`No data found`)
        }
        console.log(response)
        setTaskListData(response)
        if (response?.data) {

            setRowsCount(response?.data?.total)
            var filteredRowData = [];
            response?.data?.items?.forEach((key) => {
                if (projectId) {
                    setProject(key.project)
                }
                filteredRowData.push(
                    {
                        id: key.id,
                        name: key.name,
                        project: key.project,
                        category: key.category,
                        priority: key.priority,
                        status: key.status,
                        due_date: key.due_date,
                        user: key.user,
                        keywords: key.keywords,
                        description: key.description,
                        percent_complete: key.percent_complete,
                        milestone: key.milestone,
                        assigned: key.assigned,
                    });
            })
            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/projects/${params.row.project.id}/tasks/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'project',
                    headerName: 'Project',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/projects/${params.row.project.id}`}>{params.row.project.name}</Link>
                    )
                },
                {
                    field: 'category',
                    headerName: 'Category',
                    width: 100
                },
                {
                    field: 'priority',
                    headerName: 'Priority',
                    width: 120,
                    renderCell: (params) => (
                        <div className={params.value}>{params.value}</div>
                    )
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    width: 100
                },
                {
                    field: 'due_date',
                    headerName: 'Due Date',
                    width: 100
                },
                {
                    field: 'user',
                    headerName: 'Owner',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/users/${params.row.user.id}`}>{params.row.user.email}</Link>
                    )
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenCreateDialog(true); }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDeleteDialog(true); }} />
                        </>
                    )

                },
            ];
            setRowsData([...filteredRowData])
            setColsData(colsVals)
        }
        setLoading(false)
    }, [pageNo, projectId])

    const addControlData = async (data) => {
        if (data) {
            await getData();
        } else {
            var arrcopy = rowsData.filter(row => row.id !== data.id)
            var tmparray = [...arrcopy, data];
            setRowsData(tmparray);
            getData();
        }
        handleClose();

        //editObj = null
    }

    const isOpen = () => {
        setEditObj(null);
        getData()
        handleClose();
    }

    useEffect(() => {
        if (taskListData === null) {
            getData();
        }
    }, [getData, taskListData]);

    // useEffect(() => {
    //     console.log('rowsData', rowsData)
    // }, [rowsData])

    // useEffect(() => {
    //     console.log('colsData', colsData)
    // }, [colsData])



    return (<>
        {(showBreadcrumb &&
            <>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/system-administration">
                        System Administration
                    </Link>
                    <Link underline="hover" color="inherit" href="/projects/list">
                        Projects
                    </Link>
                    <Link underline="hover" color="inherit" href={`/projects/${projectId}`}>
                        {project?.name}
                    </Link>
                    <Typography color="text.primary">Tasks</Typography>
                </Breadcrumbs>
            </>)}
        <h1 className="tasks-title">Tasks</h1>

        <div style={{ marginTop: '60px' }} className="taskList">
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <div className="task list" style={{ height: '600px', width: '100%' }}>
                    <Button
                        variant="contained"
                        className="secondary btn"
                        startIcon={<DeleteIcon />}
                        disabled={!selected.length}
                        onClick={() =>
                            selected.length ? setOpenDeleteDialog(true) : null
                        }
                    >
                        Delete
                    </Button>
                    <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={() => { setOpenCreateDialog(true); }}
                    >
                        Create
                    </Button>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        rowCount={rowsCount}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                        onPageChange={(params) => {
                            console.log(params)
                            //setPageNo(params)
                            getData(params)
                        }}
                        paginationMode="server"
                    //onPaginationModelChange={handlePaginationModelChange}
                    //paginationModel={paginationModel}
                    />
                </div>
            </>
        </div>
        <Dialog
            //fullScreen
            open={openCreateDialog}
            onClose={handleClose}
            aria-labelledby="create-data"
            aria-describedby="alert-dialog-create-data"
        >
            <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
            <DialogTitle id="alert-dialog-title">
                {editObj ? `Edit task` : `Create task`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <CreateEditProjectTask isOpen={isOpen} controlData={addControlData} rowsData={rowsData} editDataObj={editObj} projId={projectId} />
                </DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog
            open={openDeleteDialog}
            onClose={handleClose}
            aria-labelledby="delete-confirm"
            aria-describedby="alert-dialog-delete-confirm"
        >
            <DialogTitle id="alert-dialog-title">
                {"Delete?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this task?
                </DialogContentText>
            </DialogContent>
            <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )

}