import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

//This class displays lists of aws control imports

// export async function deleteData(cap) {
//     var url = `${process.env.REACT_APP_API_URL}/cap_poams/${cap}`;

//     const response = axios(url, {
//         method: "DELETE",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${localStorage.access_token}`,
//         },
//     }).then(result => {
//         return result;
//     }).catch(error => { return error.message; })
//     return response;
// }

export async function loadProjectData(projectId) {
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadData() {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/aws_controls/aws_control_imports/`;
    const response = axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { rowId: 'abc123' },
    { rowId: 'def456' },
    { rowId: 'ghi789' },
];

export default function AWSControlImportList({projectId}) {

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);
    const [projectData, setProjectData] = useState(null);

    const [loading, setLoading] = useState(false);

    const [AWSControlImportListData, setAWSControlImportListData] = useState(null);

    const onRowsSelectionHandler = (ids) => {
        //setSelected([...ids]);
    };

    const getData = useCallback(async () => {
        var projData = await loadProjectData(projectId)
        setProjectData(projData.data);
        //setLoading(true)
        var response;
        response = await loadData();
        setAWSControlImportListData(response)

        if (response?.data != null) {
            var filteredRowDataAWSControlImports = [];
            response?.data?.forEach((key) => {
                if (key?.imported === undefined) {
                    // Value is undefined
                    window.location.reload(false)
                }
                filteredRowDataAWSControlImports.push({
                    id: key?.id,
                    name: key?.name,
                    imported: `${key?.imported ? key?.imported : 'false'}`,
                    import_results: `${key?.import_results ? key?.import_results : 'In progress'}`,
                })
                //setLoading(false)

            })

            setRowsData([...filteredRowDataAWSControlImports]);
            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                },
                {
                    field: 'imported',
                    headerName: 'Imported',
                    width: 200,
                },
                {
                    field: 'import_results',
                    headerName: 'Import Results',
                    width: 200,
                },
                // {
                //     field: 'action',
                //     headerName: 'Action',
                //     width: 200,
                //     renderCell: (params) => (
                //         <>
                //             <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                //             <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                //         </>
                //     )

                // },
            ];
            setColsData([...colsVals]);
        }
        setLoading(false)
    }, [projectId])

    useEffect(() => {
        if (AWSControlImportListData === null) {
            getData();
        }
    }, [getData, AWSControlImportListData]);

    useEffect(() => {
    }, [rowsData])


    useEffect(() => {
    }, [colsData])

    if (loading) {
        return (
            <>
                <SpinnerComponent />
            </>
        );
    }

    return (
        <div style={{ marginTop: '0px' }}>
            <>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href={`/projects/${projectId}`}>
                        {projectData?.name}
                    </Link>
                    <Link underline="hover" color="inherit" href={`/projects/${projectId}/controls`}>
                        Controls
                    </Link>
                    <Typography color="text.primary">AWS Control Imports</Typography>
                </Breadcrumbs>

                <div style={{ height: '600px', width: '100%' }}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        //checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                        getRowId={() => uuidv4()}
                    />
                </div>
            </>
        </div>
    )

}