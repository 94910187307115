import React, {Suspense, lazy} from 'react';
import SpinnerComponent from '../components/LoadingSpinner';
//import TaskList from '../components/lists/TaskList';
//import TaskCalendar from '../components/TaskCalendar';
import TaskCalendar2 from '../components/TaskCalendar2'
// const TaskCalendar = lazy(() => import('../components/TaskCalendar'));
const TaskList = lazy(() => import('../components/lists/TaskList'));
//const TaskCalendar2 = lazy(() => import('../components/TaskCalendar2'));

export default function Tasks() {
  return (
    <>
      <h1>Tasks</h1>
      <p>Alert users to upcoming tasks</p>
      { /* 
      <div className="panel calendar">
        <h2>Task Calendar</h2>
        <Suspense fallback={<SpinnerComponent />}><TaskCalendar /></Suspense> 
        <TaskCalendar />
      </div>
      */}
      <div className="panel">
        { <Suspense fallback={<SpinnerComponent />}><TaskCalendar2 /></Suspense> }
      </div>
      <div className="panel">
        { <Suspense fallback={<SpinnerComponent />}><TaskList /></Suspense> }
      </div>
    </>
  );
}