import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
//import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
//import EditIcon from '@mui/icons-material/Edit';
import Import from "../Import";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { v4 as uuidv4 } from 'uuid';


//This class displays lists of framework imports

export async function deleteData(cap) {
    var url = `${process.env.REACT_APP_API_URL}/import_frameworks/${cap}`;

    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadData() {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/import_frameworks/all`;
    const response = axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function FrameworkImportList() {
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [selected, setSelected] = useState([]);

    const [openDialog, setOpenDialog] = useState(null);

    const [frameworkImportListData, setFrameworkImportListData] = useState(null);

    const [isSystemAdmin, setIsSystemAdmin] = useState(null);

    //const [exportData, setExportData] = useState(null)

    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" ||
            localStorage.getItem("system_role") === "1" ||
            localStorage.getItem("system_role") === "2"
        ) {
            setIsSystemAdmin(true)
        } else {
            setIsSystemAdmin(false)
        }

    }, [setIsSystemAdmin])

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        //setEditObj(null);
        handleClose();
    }

    const addControlData = (data) => {
        var arrcopy = rowsData.filter(row => row.id !== data.id)
        var tmparray = [...arrcopy, data];
        setRowsData(tmparray);
    }

    async function deleteSelectedRow() {
        if (editObj) {
            const response = await deleteData(editObj.id);
            if (response?.status !== 200) {
                setErrMsg(`${response}`);
            }
            if (response?.status === 200) {
                let filteredArray = rowsData?.filter(item => item.id !== editObj.id)
                setRowsData(filteredArray);
            }
        }
        if (selected.length !== 0 && !editObj) {
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`${response}`);
                }
                if (response?.status === 200) {
                    let filteredArray = rowsData?.filter(item => item.id !== selected[x])
                    setRowsData(filteredArray);
                }
            }
        }

    };

    const getData = useCallback(async () => {
        setLoading(true)
        var response;
        response = await loadData();
        setFrameworkImportListData(response)

        if (response) {
            var filteredRowDataFrameworkImports = [];
            response?.data?.forEach((key) => {
                if (key?.imported === undefined) {
                    // Value is undefined
                    window.location.reload(false)
                }
                filteredRowDataFrameworkImports.push({
                    id: key?.id,
                    name: key?.name,
                    imported: `${key?.imported ? key?.imported : 'false'}`,
                    import_results: `${key?.import_results ? key?.import_results : 'In progress'}`,
                })
            })

            setRowsData([...filteredRowDataFrameworkImports]);
            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 200,
                },
                {
                    field: 'imported',
                    headerName: 'Imported',
                    width: 200,
                },
                {
                    field: 'import_results',
                    headerName: 'Import Results',
                    width: 200,
                },
                {/*
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                        </>
                    )

                */}
            ];
            setColsData([...colsVals]);
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (frameworkImportListData === null) {
            getData();
        }
    }, [getData, frameworkImportListData]);

    useEffect(() => {
    }, [rowsData])


    useEffect(() => {
    }, [colsData])


    return (
        <div style={{ marginTop: '0px' }}>
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/system-administration">
                        System Administration
                    </Link>
                    <Typography color="text.primary">Framework Imports</Typography>
                </Breadcrumbs>
                {isSystemAdmin && <div className="btns-top-list">
                    { /*<Button
                        variant="contained"
                        className="delete secondary btn"
                        startIcon={<DeleteIcon />}
                        disabled={!selected.length}
                        onClick={() =>
                            selected.length ? setOpenDialog("delete") : null
                        }
                    >
                        Delete
                    </Button> */}
                    <Button
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={() => { setOpenAddDialog(true); }}
                        className="addBtn"
                    >Import</Button>
                </div>}

                <div style={{ height: '600px', width: '100%' }}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        //checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                        getRowId={() => uuidv4()}
                    />
                </div>
                <Dialog
                    //fullScreen
                    open={openAddDialog ? openAddDialog : false}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "500px",  // Set your width here
                            },
                        },
                    }}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Import Framework` : `Import Framework`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Import isOpen={isOpen} editDataObj={editObj} controlData={addControlData} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog ? openDialog : false}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this? This will also delete any associated data.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )

}