import React from 'react';
import metadata from '../metadata.json';
import '../css/Footer.css';

function FooterComponent() {
    return (
      <div className="sf-footer">
        &copy; 2025 - Longevity Risk Management
        <div className="sf-footer-version">
          {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
        </div>
      </div>
    );
  }
  export default FooterComponent;