import React, { useState } from 'react';

import * as go from 'gojs';
import { ReactDiagram, ReactPalette } from 'gojs-react';

import '../css/App.css';  // contains .diagram-component CSS

let diagram;

export default function FlowChart({projectId, flowchartId}) {
    const [nodeData, setNodeData] = useState([
        { "key": -1, "category": "Start", "loc": "-250 -400", "text": "Start" },
        { "key": -2, "category": "End", "loc": "200 0", "text": "End" },
        { "text": "Step 1: Request Role Recruitment", "key": -3, "loc": "-250 -300" },
        { "text": "Step 2: Identify Candidates", "key": -4, "loc": "-240.16796875 -209.375" },
        { "text": "Step 3: Interview Candidates", "key": -5, "loc": "-220.7578125 -124.703125" },
        { "text": "Step 5: Onboard Employee", "key": -6, "loc": "-35.09375 87.1640625" },
        { "text": "Step 4: Extend Job Offer", "key": -7, "loc": "-212.6484375 -4.4453125" },
        { "category": "Conditional", "text": "Is candidate a good fit?", "key": -8, "loc": "0 -300" },
        { "category": "Conditional", "text": "Is offer accepted?", "key": -9, "loc": "353.08203125 -142.328125" },
        { "category": "Comment", "text": "Employee Recruiting Process", "key": -10, "loc": "300 -400" }
    ])
    const [linkData, setLinkData] = useState([
        { "from": -1, "to": -3 },
        { "from": -3, "to": -4 },
        { "from": -4, "to": -5 },
        { "from": -5, "to": -8 },
        { "from": -8, "to": -7, "text": "Yes" },
        { "from": -9, "to": -6, "text": "Yes" },
        { "from": -6, "to": -2 },
        { "from": -7, "to": -9 },
        { "from": -8, "to": -4, "text": "No" },
        { "from": -9, "to": -4, "text": "No" }
    ])

    // define some custom shapes for node templates
    function defineFigures() {
        go.Shape.defineFigureGenerator('Conditional', (shape, w, h) => {
            const geo = new go.Geometry();
            const fig = new go.PathFigure(w * 0.15, 0, true);
            geo.add(fig);
            fig.add(new go.PathSegment(go.SegmentType.Line, w * 0.85, 0));
            fig.add(new go.PathSegment(go.SegmentType.Line, w, 0.5 * h));
            fig.add(new go.PathSegment(go.SegmentType.Line, w * 0.85, h));
            fig.add(new go.PathSegment(go.SegmentType.Line, w * 0.15, h));
            fig.add(new go.PathSegment(go.SegmentType.Line, 0, 0.5 * h).close());
            geo.spot1 = new go.Spot(0.15, 0);
            geo.spot2 = new go.Spot(0.85, 1);
            return geo;
        });

        // taken from https://cdn.jsdelivr.net/npm/create-gojs-kit@3.0.18/dist/extensions/Figures.js:
        go.Shape.defineFigureGenerator('File', (shape, w, h) => {
            const geo = new go.Geometry();
            const fig = new go.PathFigure(0, 0, true); // starting point
            geo.add(fig);
            fig.add(new go.PathSegment(go.SegmentType.Line, 0.75 * w, 0));
            fig.add(new go.PathSegment(go.SegmentType.Line, w, 0.25 * h));
            fig.add(new go.PathSegment(go.SegmentType.Line, w, h));
            fig.add(new go.PathSegment(go.SegmentType.Line, 0, h).close());
            const fig2 = new go.PathFigure(0.75 * w, 0, false);
            geo.add(fig2);
            // The Fold
            fig2.add(new go.PathSegment(go.SegmentType.Line, 0.75 * w, 0.25 * h));
            fig2.add(new go.PathSegment(go.SegmentType.Line, w, 0.25 * h));
            geo.spot1 = new go.Spot(0, 0.25);
            geo.spot2 = go.Spot.BottomRight;
            return geo;
        });
    }

    function initDiagram() {
        defineFigures();
        // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
        diagram =
            new go.Diagram(
                {
                    'undoManager.isEnabled': true,  // must be set to allow for model change listening
                    // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
                    'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
                    model: new go.GraphLinksModel(
                        {
                            linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                        })
                });
        // set up some colors/fonts for the default ('light') and dark Themes
        diagram.themeManager.set('light', {
            colors: {
                text: '#fff',
                start: '#064e3b',
                step: '#49939e',
                conditional: '#6a9a8a',
                end: '#7f1d1d',
                comment: '#a691cc',
                bgText: '#000',
                link: '#dcb263',
                linkOver: '#cbd5e1',
                div: '#ede9e0'
            }
        });

        // diagram.themeManager.set('dark', {
        //     colors: {
        //         text: '#fff',
        //         step: '#414a8d',
        //         conditional: '#88afa2',
        //         comment: '#bfb674',
        //         bgText: '#fff',
        //         link: '#fdb71c',
        //         linkOver: '#475569',
        //         div: '#141e37'
        //     }
        // });
        // helper definitions for node templates
        function nodeStyle(node) {
            node
                // the Node.location is at the center of each node
                .set({ locationSpot: go.Spot.Center })
                // The Node.location comes from the "loc" property of the node data,
                // converted by the Point.parse static method.
                // If the Node.location is changed, it updates the "loc" property of the node data,
                // converting back using the Point.stringify static method.
                .bindTwoWay('location', 'loc', go.Point.parse, go.Point.stringify);
        }

        function shapeStyle(shape) {
            // make the whole node shape a port
            shape.set({ strokeWidth: 0, portId: '', cursor: 'pointer' });
        }

        function textStyle(textblock) {
            textblock.set({ font: 'bold 11pt Figtree, sans-serif' }).theme('stroke', 'text');
        }


        // define a simple Node template
        diagram.nodeTemplate =
            new go.Node('Auto').apply(nodeStyle)  // the Shape will go around the TextBlock
                .bindTwoWay('location', 'loc', go.Point.parse, go.Point.stringify)
                .add(
                    new go.Shape('Rectangle',
                        {
                            name: 'SHAPE',
                            fill: 'white',
                            strokeWidth: 0,
                            fromLinkable: true,
                            toLinkable: true,
                            fromSpot: go.Spot.AllSides,
                            toSpot: go.Spot.AllSides
                        })
                        // Shape.fill is bound to Node.data.color
                        .bind('fill', 'color'),
                    new go.TextBlock({
                        margin: 12,
                        maxSize: new go.Size(160, NaN),
                        wrap: go.Wrap.Fit,
                        editable: true
                    })  // some room around the text
                        .bindTwoWay('text')
                );
        diagram.nodeTemplateMap.add(
            'Conditional',
            new go.Node('Auto').apply(nodeStyle)
                .add(
                    new go.Shape('Conditional', { fromLinkable: true, toLinkable: true }).apply(shapeStyle).theme('fill', 'conditional'),
                    new go.TextBlock({
                        margin: 8,
                        maxSize: new go.Size(160, NaN),
                        wrap: go.Wrap.Fit,
                        textAlign: 'center',
                        editable: true
                    })
                        .apply(textStyle)
                        .bindTwoWay('text')
                )
        );
        diagram.nodeTemplateMap.add(
            'Start',
            new go.Node('Auto')
                .apply(nodeStyle)
                .add(
                    new go.Shape('Capsule', { fromLinkable: true }).apply(shapeStyle).theme('fill', 'start'),
                    new go.TextBlock('Start', {
                        margin: new go.Margin(5, 6),
                        editable: true
                    }).apply(textStyle).bindTwoWay('text')
                )
        );

        diagram.nodeTemplateMap.add(
            'End',
            new go.Node('Auto')
                .apply(nodeStyle)
                .add(
                    new go.Shape('Capsule', { toLinkable: true }).apply(shapeStyle).theme('fill', 'end'),
                    new go.TextBlock('End', { margin: new go.Margin(5, 6), editable: true }).apply(textStyle).bindTwoWay('text')
                )
        );

        diagram.nodeTemplateMap.add(
            'Comment',
            new go.Node('Auto').apply(nodeStyle)
                .add(
                    new go.Shape('File', { strokeWidth: 3 }).theme('fill', 'div').theme('stroke', 'comment'),
                    new go.TextBlock({
                        font: '9pt Figtree, sans-serif',
                        margin: 8,
                        maxSize: new go.Size(200, NaN),
                        wrap: go.Wrap.Fit,
                        textAlign: 'center',
                        editable: true
                    })
                        .theme('stroke', 'bgText')
                        .bindTwoWay('text')
                    // no ports, because no links are allowed to connect with a comment
                )
        );
        // replace the default Link template in the linkTemplateMap
        diagram.linkTemplate = new go.Link({
            routing: go.Routing.AvoidsNodes,
            curve: go.Curve.JumpOver,
            corner: 5,
            toShortLength: 4,
            relinkableFrom: true,
            relinkableTo: true,
            reshapable: true,
            resegmentable: true,
            // mouse-overs subtly highlight links:
            mouseEnter: (e, link) => (link.findObject('HIGHLIGHT').stroke = link.diagram.themeManager.findValue('linkOver', 'colors')),
            mouseLeave: (e, link) => (link.findObject('HIGHLIGHT').stroke = 'transparent'),
            // context-click creates an editable link label
            contextClick: (e, link) => {
                e.diagram.model.commit((m) => {
                    m.set(link.data, 'text', 'Label');
                });
            }
        })
            .bindTwoWay('points')
            .add(
                // the highlight shape, normally transparent
                new go.Shape({
                    isPanelMain: true,
                    strokeWidth: 8,
                    stroke: 'transparent',
                    name: 'HIGHLIGHT'
                }),
                // the link path shape
                new go.Shape({ isPanelMain: true, strokeWidth: 2 })
                    .theme('stroke', 'link'),
                // the arrowhead
                new go.Shape({ toArrow: 'standard', strokeWidth: 0, scale: 1.5 })
                    .theme('fill', 'link'),
                // the link label
                new go.Panel('Auto', { visible: false })
                    .bind('visible', 'text', (t) => typeof t === 'string' && t.length > 0) // only shown if there is text
                    .add(
                        // a gradient that fades into the background
                        new go.Shape('Ellipse', { strokeWidth: 0 })
                            .theme('fill', 'div', null, null, c => new go.Brush("Radial", { 0: c, 0.5: `${c}00` })),
                        new go.TextBlock({
                            name: 'LABEL',
                            font: '9pt Figtree, sans-serif',
                            margin: 3,
                            editable: true
                        })
                            .theme('stroke', 'bgText')
                            .bindTwoWay('text')
                    )
            );

        // temporary links used by LinkingTool and RelinkingTool are also orthogonal:
        diagram.toolManager.linkingTool.temporaryLink.routing = go.Routing.Orthogonal;
        diagram.toolManager.relinkingTool.temporaryLink.routing = go.Routing.Orthogonal;
        return diagram;
    }

    function initPalette() {
        const $ = go.GraphObject.make;

        // Create a new palette
        const palette = $(go.Palette);

        // Define the Node template for the palette
        palette.nodeTemplate = $(
            go.Node,
            "Auto", // Automatically arranges Shape and TextBlock
            $(go.Shape, "RoundedRectangle", { fill: "lightblue" }), // Shape for the node
            $(
                go.TextBlock,
                {
                    margin: 5, // Margin around the text
                    editable: true, // Allow text editing
                },
                new go.Binding("text").makeTwoWay() // Bind text property
            )
        );

        // Define the palette's model with initial nodes
        palette.model = new go.GraphLinksModel([
            { key: "Start", category: "Start", text: "Start" },
            { key: "Step", text: "Step" },
            { key: "Conditional", category: "Conditional", text: "???" },
            { key: "End", category: "End", text: "End" },
            { key: "Comment", category: "Comment", text: "Comment" },
        ]);

        return palette;
    }

    /**
     * This function handles any changes to the GoJS model.
     * It is here that you would make any updates to your React state, which is discussed below.
     */
    function handleModelChange(changes) {
        console.log(`GoJS model changed! ${JSON.stringify(changes)}`);
        if (changes?.insertedNodeKeys != null && changes?.insertedNodeKeys.length === 1) {
            const newNode = changes.modifiedNodeData[0].category
                ? { category: changes.modifiedNodeData[0].category, key: changes.modifiedNodeData[0].key, text: changes.modifiedNodeData[0].text, loc: changes.modifiedNodeData[0].loc }
                : { key: changes.modifiedNodeData[0].key, text: changes.modifiedNodeData[0].text, loc: changes.modifiedNodeData[0].loc };

            setNodeData([...nodeData, newNode]);
        }
        if (changes?.insertedLinkKeys != null && changes?.insertedLinkKeys.length === 1 && changes?.modifiedLinkData != null) {
            //add new link key
            const newLink = changes.modifiedLinkData[0]
            let formatLink = {"from": newLink.from, "to": newLink.to}
            if (newLink.text != null) {
                formatLink = {"from": newLink.from, "to": newLink.to, "text": newLink.text}
            }
            setLinkData([...linkData, formatLink])
        }
        if (changes?.modifiedNodeData != null) {
            //iterate through nodeData
            changes?.modifiedNodeData?.forEach((item) => {
                console.log(JSON.stringify(item))
                //filter out modified node
                const updatedNodeData = nodeData.filter((elem) => elem.key !== item.key);
                //update node data
                setNodeData([...updatedNodeData, item])
            })
        }
        // if (changes?.modifiedLinkData != null) {
        //     //iterate through nodeData
        //     changes?.modifiedLinkData?.map((item) => {
        //         console.log(JSON.stringify(item))
        //         //filter out modified node
        //         const updatedLinkData = linkData.filter((elem) => elem.key !== item.key);
        //         //update node data
        //         setLinkData([...updatedLinkData, item])
        //     })
        // }
        // If nodes removed
        if (changes?.removedNodeKeys != null) {
            //remove data from array that matches key
            changes?.removedNodeKeys?.forEach((item) => {
                // Create a new array without the item to be removed
                const newNodes = nodeData.filter((elem) => elem.key !== item);
                // Update the state with the new array
                setNodeData(newNodes);
            })
        }
        // If links removed
        // if (changes?.removedLinkKeys != null) {
        //     //remove data from array that matches key
        //     changes?.removedLinkKeys?.map((item) => {
        //         // Create a new array without the item to be removed
        //         const newLinks = linkData.filter((elem) => elem.key !== item);
        //         // Update the state with the new array
        //         setLinkData(newLinks);
        //     })
        // }

    }

    return (
        <div>
            <ReactPalette
                initPalette={initPalette}
                divClassName='paletteDiv'
            />
            <ReactDiagram
                initDiagram={() => initDiagram()}
                divClassName='diagram-component'
                div='diagramDiv'
                nodeDataArray={nodeData}
                linkDataArray={linkData}
                onModelChange={handleModelChange}
            />
            ...
        </div>
    );
}