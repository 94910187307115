import React, { useState, useEffect, useCallback, useMemo, lazy, Suspense } from "react";
import { MenuItem, Select } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import SpinnerComponent from "../components/LoadingSpinner";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const RiskCharts = lazy(() => import("../components/graphs/RiskCharts"));
const RiskAnalysis = lazy(() => import("../components/graphs/RiskAnalysis"));

export async function getProjectData() {
  let url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`;
  if (localStorage.getItem("system_role") === "4") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching project data:", error);
    return { error: error.message };
  }
}

export async function getChartData(id) {
  const url = `${process.env.REACT_APP_API_URL}/dashboards/risk/metrics/?project_id=${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching chart data:", error);
    return { error: error.message };
  }
}

export default function Risk() {
  const { control, handleSubmit } = useForm({
    defaultValues: { project: null },
  });
  const [projects, setProjects] = useState([{ value: "0", label: "Please select..." }]);
  const [projectId, setProjectId] = useState(null);
  const [chartData, setChartData] = useState(null);
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const getDropDownOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    if (jsonData?.data) {
      const projectOptions = jsonData.data.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions]);
    }
  }, [selectOption]);

  const getCharts = async (id) => {
    const jsonData = await getChartData(id);
    setChartData(jsonData?.data || []);
  };

  const handleChangeProjectSelect = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      setProjectId(selectedValue);
      getCharts(selectedValue);
    }
  };

  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  return (
    <>
      <h1>Risk Dashboard</h1>
      <form onSubmit={handleSubmit()}>
        <div className="form-field control">
          <label>Select a Project</label>
          <Controller
            name="project"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={handleChangeProjectSelect}
                value={projectId ? projectId : 0}
                inputProps={{ "data-testid": "project-select" }}
              >
                {projects.map((project) => (
                  <MenuItem value={project.value} key={project.value}>
                    {project.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
      </form>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year"]}
          label="Year"
          value={dayjs().year(selectedYear)} // Convert integer to Day.js object
          onChange={(newValue) => {
            if (newValue) {
              setSelectedYear(newValue.year()) // Extract integer year
            }
            if (projectId) {
              (getCharts(projectId));
             } 
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      {projectId && (
        <Suspense fallback={<SpinnerComponent />}>
          <RiskAnalysis projectId={projectId} year={selectedYear} />
        </Suspense>
      )}
      <Suspense fallback={<SpinnerComponent />}>
        <RiskCharts chartData={chartData} />
      </Suspense>
    </>
  );
}
