import React, { useState, useEffect, useCallback, useRef } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction";
import LoadingSpinner from './LoadingSpinner';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import '../css/TaskCalendar.css';
//import { Button } from '@mui/material';
//import format from 'date-fns/format';

// const formatDate = (date) => {
//   return format(new Date(date), 'yyyy-MM-dd');
// };

export default function FullCalendarComponent() {
  const calendarRef = useRef(null);
  const [wbsId, setWbsId] = useState();
  const [loading, setLoading] = useState(false);
  const [marks, setMarks] = useState(null);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [eventPopover, setEventPopover] = useState(null);
  // var date = new Date();
  // const currentStart = formatDate(new Date(date.getFullYear() -1, 0, 1))
  // const currentEnd = formatDate(new Date(date.getFullYear() +1, 12, 0))
  //const [monthCount, setMonthCount] = useState(1)
  //const [calTitle, setCalTitle] = useState(`${currentStart} to ${currentEnd}`)

  // const handleNextClick = () => {
  //   const calendarApi = calendarRef.current.getApi();
  //   calendarApi.next();
  //   let newmonthcount = monthCount + 1
  //   setMonthCount(newmonthcount)
  //   setCurrentStart(formatDate(new Date(date.getFullYear(), date.getMonth() + newmonthcount, 0)))
  //   setCurrentEnd(formatDate(new Date(date.getFullYear(), date.getMonth() + (newmonthcount + 1), 0)))
  //   setCalTitle(`${currentStart} to ${currentEnd}`)
  //   setMarks(null)
  // }

  // const handlePrevClick = () => {
  //   const calendarApi = calendarRef.current.getApi();
  //   calendarApi.prev();
  //   let newmonthcount = monthCount - 1
  //   setMonthCount(newmonthcount)
  //   setCurrentStart(formatDate(new Date(date.getFullYear(), date.getMonth() + newmonthcount, 0)))
  //   setCurrentEnd(formatDate(new Date(date.getFullYear(), date.getMonth() + (newmonthcount + 1), 0)))
  //   setCalTitle(`${currentStart} to ${currentEnd}`)
  //   setMarks(null)
  // };

  const handlePopoverOpen = (event) => {
    setEventPopover(event.event._def)
    setAnchorEl(event.jsEvent.fromElement);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleEventClick = (arg) => {
    window.location.replace(`/projects/${arg.event._def.extendedProps.project_id}/tasks/${arg.event._def.publicId}`)
  };

  useEffect(() => {
    if (location?.pathname !== null) {
      setWbsId(location.pathname.split("/")[4])
    }
  }, [location?.pathname])

  const getTasksData = useCallback(async () => {
    var url = "";
    if (!wbsId) {
      url = `${process.env.REACT_APP_API_URL}/tasks/start_end_date/`;
      //url = `${process.env.REACT_APP_API_URL}/tasks/start_end_date/?start_date=${currentStart}&end_date=${currentEnd}`;
    }
    else {
      url = `${process.env.REACT_APP_API_URL}/tasks/start_end_date/?wbs_id=${wbsId}`;
      //url = `${process.env.REACT_APP_API_URL}/tasks/start_end_date/?start_date=${currentStart}&end_date=${currentEnd}&wbs_id=${wbsId}`;
    }
    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => {
      //console.log(error)
      return error.message;
    })
    return response;
  }, [/*currentEnd, currentStart,*/wbsId])

  const getData = useCallback(async () => {
    setLoading(true);
    setMarks(null)
    const jsonData = await getTasksData();
    if (jsonData === 'Request failed with status code 401') {
      //setErrMsg('Request failed with status code 401')
      localStorage.clear();
      window.location.reload(false);
    }
    if (jsonData.error) {
      //handle the error
      alert(`There was an error: ${JSON.stringify(jsonData)}`);
    }
    if (jsonData === 'Request failed with status code 404') {
      //handle the error
      setMarks([])
    }
    if (jsonData?.data) {
      console.log(jsonData?.data)
      let taskData = [];
      jsonData?.data?.forEach(item => {
        taskData.push({
          date: new Date(`${item.due_date} 00:00:00`),
          priority: item?.priority,
          name: item?.name,
          title: item?.name,
          id: item?.id,
          description: item?.description,
          percent_complete: item?.percent_complete,
          project_id: item?.project_id,
          due_date: item?.due_date,
          status: item?.status,
          //assigned: item?.assigned?.email,
          className: `calendar-priority-${item?.priority}`,
        });
      });
      setMarks(taskData);

    }
    setLoading(false);
  }, [getTasksData])

  useEffect(() => {
    if (marks === null) {
      getData()
    }
  }, [marks, getData])


  if (loading) {
    return (
      <>
        <LoadingSpinner />
      </>
    )
  }

  return (
    <div className="fullcalendar">
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={false}
        eventClick={handleEventClick}
        eventMouseEnter={handlePopoverOpen}
        eventMouseLeave={handlePopoverClose}
        events={marks}
        eventContent={renderEventContent}
        ref={calendarRef}
        /*headerToolbar={{
          left: '',
          center: 'title',
          right: ''
        }}*/
        // eventSources={
        //   {
        //     events: function (fetchInfo, successCallback, failureCallback) {
        //       // ...
        //       let url;
        //       let start = fetchInfo.startStr.split("T")
        //       let end = fetchInfo.endStr.split("T")
        //       if (!wbsId) {
        //         url = `${process.env.REACT_APP_API_URL}/tasks/start_end_date/?start_date=${start[0]}&end_date=${end[0]}`;

        //       }
        //       else {
        //         url = `${process.env.REACT_APP_API_URL}/tasks/start_end_date/?start_date=${start[0]}&end_date=${end[0]}&wbs_id=${wbsId}`;
        //       }
        //       axios(url, {
        //         method: "GET",
        //         headers: {
        //           "Content-Type": "application/json",
        //           "Authorization": `Bearer ${localStorage.access_token}`,
        //         },
        //       }).then(result => {
        //         console.log(result?.data)
        //         // let entriesArray = Object.entries(result?.data)
        //         // const transformedArray = entriesArray.map(([key, value]) => ({ id: key, ...value }));
        //         successCallback(
        //           // transformedArray.map((eventEl) => {
        //           //   return {
        //           //     title: eventEl["name"],
        //           //     start: eventEl["due_date"]
        //           //   }
        //           // })
        //           setMarks(result?.data)
        //         )
        //         //return result;
        //       }).catch(error => {
        //         //console.log(error)
        //         //return error.message;
        //         failureCallback(error);
        //       })
        //     },
        //     color: 'yellow',   // an option!
        //     textColor: 'black' // an option!
        //   }
        // }
        views={
          {
            dayGridMonth: {
              titleFormat: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }
            }
          }
        }
      />
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>Name: {eventPopover?.title}</Typography>
        <Typography sx={{ p: 1 }}>Due Date: {eventPopover?.extendedProps?.due_date}</Typography>
        <Typography sx={{ p: 1 }}>Priority: {eventPopover?.extendedProps?.priority}</Typography>
        <Typography sx={{ p: 1 }}>Percent complete: {eventPopover?.extendedProps?.percent_complete} %</Typography>
        <Typography sx={{ p: 1 }}>Status: {eventPopover?.extendedProps?.status}</Typography>
      </Popover>
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}