import React, { useState, useEffect, useCallback, useMemo, lazy, Suspense } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import SpinnerComponent from '../components/LoadingSpinner';
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const ComplianceCharts = lazy(() => import('../components/graphs/ComplianceCharts'));

/* 
********START all endpoints called
*/

export async function getProjectData() {
  var url;
  url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`
  if (localStorage.getItem("system_role") === "4") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`
  }
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getProjectFrameworkData(id) {
  var url = `${process.env.REACT_APP_API_URL}/projects/get_project_frameworks/${id}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

/* 
********END all endpoints called
*/

export default function Compliance() {
  const [projects, setProjects] = useState([{ value: "0", label: "Please select..." }]);
  const [projectId, setProjectId] = useState(null);
  const [framework, setFramework] = useState([]);
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const { control, handleSubmit } = useForm({
    defaultValues: {
      project: {},
    }
  });

  const getOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
    }
    if (jsonData?.data) {
      const projectOptions = jsonData?.data?.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions])
    }
  }, [selectOption])

  async function getCharts(id) {
    const jsonProjectFrameworks = await getProjectFrameworkData(id);
    setFramework(jsonProjectFrameworks.data)
  }

  const handleChangeProjectSelect = (event) => {
    getCharts(event.target.value)
    setProjectId(event.target.value)
  };

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  return (
    <>
      <h1>Compliance</h1>
      <form onSubmit={handleSubmit()}>
        <div className="form-field control">
          <label>Select a Project</label>
          <Controller
            name="project"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeProjectSelect}
                value={projectId ? projectId : 0}
                inputProps={{ "data-testid": "project-select" }}
              >
                {
                  projects?.map((project) => {
                    return <MenuItem
                      value={project.value}
                      key={project.value}>
                      {project.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
      </form>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year"]}
          label="Year"
          value={dayjs().year(selectedYear)} // Convert integer to Day.js object
          onChange={(newValue) => {
            if (newValue) {
              setSelectedYear(newValue.year()) // Extract integer year
            }
            if (projectId) {
              getCharts(projectId);
            }
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Suspense fallback={<SpinnerComponent />}><ComplianceCharts projectId={projectId} framework={framework} year={selectedYear} /></Suspense>
    </>
  );
}