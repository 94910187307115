import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

/* This code is used for creating and editing data objects: frameworks, controls, 
 * projects, project groups, control classes etc.
*/
export async function getKeywords() {
    const url = `${process.env.REACT_APP_API_URL}/keyword/`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getObjProject(type, id) {
    const url = `${process.env.REACT_APP_API_URL}/${type}/${id}`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getFedriskObjIds(type, projectId) {
    var url = `${process.env.REACT_APP_API_URL}/${type}/?project_id=${projectId}`
    if (type === "project_controls") {
        url = `${process.env.REACT_APP_API_URL}/projects/project_controls/${projectId}?sort_by=-created_date&offset=0&limit=1500`
    }
    if (type === `audit_tests`) {
        url = `${process.env.REACT_APP_API_URL}/${type}/?project_id=${projectId}&sort_by=-created_date&limit=100&offset=0`
    }
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getData() {
    //call data endpoint for data type to set dropdown options
    var url = `${process.env.REACT_APP_API_URL}/users/get_users_tenant`;

    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function CreateEditDocument({
    editDataObj,
    handleComplete,
    isWizard,
    isOpen,
    controlData,
    dataType,
    dataId,
    projectId,
    //setProject 
}) {

    const [errMsg, setErrMsg] = useState("");

    const [ownerId, setOwnerId] = useState(editDataObj?.owner_id);

    const [owners, setOwners] = useState([]);
    const [document, setDocument] = useState(null);
    //const [documentType, setDocumentType] = useState('');

    const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

    const [keywordSelections, setKeywordSelections] = useState([]);
    const [fedriskObjType, setFedriskObjType] = useState(`${editDataObj?.type ? editDataObj?.type : dataType}`)
    const [fedriskObjId, setFedriskObjTypeId] = useState(`${editDataObj?.fedrisk_obj_id ? editDataObj?.fedrisk_obj_id : dataId}`)
    const [fedriskObjIdSelections, setFedriskObjTypeIdSelections] = useState([])

    const [keywordId, setKeywordId] = useState();
    const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])
    const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

    const fedriskObjTypeSelections = [
        selectOption,
        { value: "audit_tests", label: "Audit Test" },
        { value: "projects", label: "Project" },
        { value: "project_controls", label: "Project Control" },
        { value: "risks", label: "Risk" },
    ]

    const getDropDownOptionsId = useCallback(async (type) => {
        const jsonData = await getFedriskObjIds(type, projectId);
        var tmpoptions = []
        if (jsonData?.data != null) {
            if (type !== "project_controls") {
                tmpoptions = jsonData.data.items.map((obj) => ({
                    id: obj.id,
                    name: obj.name,
                }));
            }
            else {
                tmpoptions = jsonData.data.items.map((obj) => ({
                    id: obj.id,
                    name: obj.control.name,
                }));
            }
        }
        var allOptions = [keywordSelectOption, ...tmpoptions];
        setFedriskObjTypeIdSelections(allOptions);
    }, [keywordSelectOption, projectId])

    useEffect(() => {
        if (editDataObj?.keywords) {
            let options = []
            for (let i = 0; i < editDataObj?.keywords.length; i++) {
                options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
            }
            setKeywordSelections(options)
        }
    }, [editDataObj])

    useEffect(() => {
        getDropDownOptionsId(fedriskObjType)
    }, [fedriskObjType, getDropDownOptionsId])


    const getDropDownOptions = useCallback(async () => {
        const jsonData = await getKeywords();
        if (jsonData?.data != null) {
            const tmpoptions = jsonData.data.map((word) => ({
                id: word.id,
                name: word.name,
            }));
            var allOptions = [keywordSelectOption, ...tmpoptions];
            setKeywords(allOptions);
        }

    }, [keywordSelectOption])

    useEffect(() => {
        if (keywords.length === 1) {
            getDropDownOptions();
        }

    }, [getDropDownOptions, keywords.length]);

    async function postData(data) {
        //look at keywords
        if (!/^[a-z,]*$/.test(data?.newKeywords)) {
            setErrMsg(`Sorry your keywords contain values other than lowercase characters and commas`)
        } else {
            //clean up duplicate vals
            var cleanedKeywords = []
            var enteredKeywords = data.newKeywords.split(',')
            for (let x = 0; x < enteredKeywords.length; x++) {
                let match = false
                for (let y = 0; y < keywordSelections.length; y++) {
                    if (enteredKeywords[x] === keywordSelections[y].name) {
                        match = true
                    }
                }
                if (match === false) {
                    cleanedKeywords.push(enteredKeywords[x])
                }
            }
            for (let p = 0; p < keywordSelections.length; p++) {
                cleanedKeywords.push(keywordSelections[p].name)
            }
            var cleanedWords = cleanedKeywords.join(',')

            var url = `${process.env.REACT_APP_API_URL}/documents/?title=${data.title}&description=${data.description}&fedrisk_object_type=${fedriskObjType}&fedrisk_object_id=${fedriskObjId}&version=${data.version}&owner_id=${ownerId}&keywords=${cleanedWords}&project_id=${projectId}`

            let postHeaders = {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.access_token}`,
            }
            var body =
            {
                fileobject: document,
            }
            var method = "POST";
            //if a data object is being edited
            if (editDataObj) {
                method = "PUT";
                url = `${process.env.REACT_APP_API_URL}/documents/${editDataObj.id}?title=${data.title}&description=${data.description}&fedrisk_object_type=${fedriskObjType}&fedrisk_object_id=${fedriskObjId}&version=${data.version}&owner_id=${ownerId}&keywords=${cleanedWords}&project_id=${projectId}`;
            }
            const response = axios(url, {
                method: method,
                headers: postHeaders,
                data: body,
            }).then(result => {
                return result;
            }).catch(error => { return error.message; })
            return response;

        }
    }

    useEffect(() => {
    }, [errMsg])


    const handleChangeKeywordSelect = (event) => {
        setKeywordId(event.target.value);
        var keyword = keywords.filter(word => word.id === event.target.value)
        var name = keyword[0].name
        setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
        var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
        setKeywords(tmpfilterSelect)
    }

    const handleDocumentAsFile = (e) => {
        setDocument(e.target.files[0]);
        //setDocumentType(e.target.files[0].type);
        //console.log(documentType)
    }

    const { control, handleSubmit, register } = useForm({
        defaultValues: {
            title: editDataObj?.title,
            description: editDataObj?.description,
            owner: editDataObj?.owner_id,
            version: editDataObj?.version,
            newKeywords: "",
            keywordSelect: {},
            fedriskObjTypeSelect: {},
            fedriskObjIdSelect: {},
        }
    });

    const handleChangeOwnerSelect = (event) => {
        setOwnerId(event.target.value);
    }

    const handleChangeFedriskObjTypeSelect = (event) => {
        setFedriskObjTypeIdSelections([])
        setFedriskObjType(event.target.value);
        //get dropdown ids for fedrisk object type if selection is not project
        if (event.target.value !== "projects") {
            getDropDownOptionsId(event.target.value);
        }
    }

    const handleChangeFedriskObjIdTypeSelect = (event) => {
        setFedriskObjTypeId(event.target.value);
    }

    const onSubmit = async (data) => {
        const response = await postData(data);
        controlData(response.data);

        if (!isWizard) {
            isOpen(false)
        }
        if (isWizard) {
            handleComplete(true);
        }

    }

    const removeKeyword = (key) => {
        var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
        setKeywordSelections(tmpfilter);
        var keyword = keywordSelections.filter(word => word.id === key.id)
        var name = keyword[0].name
        setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
    }

    const getOptions = useCallback(async () => {
        const jsonDataUsers = await getData();
        if (jsonDataUsers.error) {
            //handle the error
            alert(`There was an error ${JSON.stringify(jsonDataUsers.error)}`)
        }
        if (jsonDataUsers?.data != null) {
            var tmpusers = [];
            jsonDataUsers.data.forEach(user => {
                tmpusers.push(
                    {
                        value: user.id,
                        label: user.email
                    }
                )
            })
            var userOptions = [selectOption, ...tmpusers];
            setOwners(userOptions);
        }
    }, [selectOption])

    useEffect(() => {

        getOptions();


    }, [getOptions]);

    return (
        <>
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="create edit document">

                <div className="form-field title">
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => <TextField required label="Title" {...field} fullWidth />}
                    />
                </div>
                <div className="form-field description">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                label="Description"
                                fullWidth
                            />
                        }
                    />
                </div>
                <div className="form-field version">
                    <Controller
                        name="version"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                label="Version"
                                fullWidth
                            />
                        }
                    />
                </div>
                <div className="form-field control">
                    <label>Existing Keywords</label>
                    <Controller
                        name="keywordSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                value={keywordId ? keywordId : 0}
                                defaultValue={0}
                                onChange={handleChangeKeywordSelect}
                                inputProps={{ "data-testid": "keyword" }}
                            >
                                {
                                    keywords?.map((word) => {
                                        return <MenuItem
                                            value={word.id}
                                            key={word.id}>
                                            {word.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <ul>
                    {(keywordSelections && (
                        keywordSelections.map((word) => {
                            return <li>
                                {word.name}
                                <IconButton aria-label="delete">
                                    <DeleteIcon onClick={() => { removeKeyword(word); }} />
                                </IconButton>
                            </li>
                        })
                    )
                    )}
                </ul>
                <div className="form-field newkeywords">
                    <Controller
                        name="newKeywords"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                label="Add new keywords"
                                //fullWidth
                                helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                            />

                        }
                    />
                </div>
                <div className="form-field project-admin">
                    <label>Owner *</label>
                    <Controller
                        name="owner"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeOwnerSelect}
                                value={ownerId ? ownerId : 0}
                                defaultValue={0}
                                inputProps={{ "data-testid": "owner" }}
                            >
                                {
                                    owners?.map((user) => {
                                        return <MenuItem
                                            value={user.value}
                                            key={user.value}>
                                            {user.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>}
                    />
                    <FormHelperText>Please select a document owner</FormHelperText>
                </div>
                {(projectId &&
                    <div className="form-field fedriskobjtype">
                        <label>Attached to *</label>
                        <Controller
                            name="fedriskObjTypeSelect"
                            control={control}
                            defaultValue={0}
                            render={({ field }) =>
                                <Select
                                    {...field}
                                    onChange={handleChangeFedriskObjTypeSelect}
                                    defaultValue={0}
                                    value={fedriskObjType ? fedriskObjType : 0}
                                >
                                    {
                                        fedriskObjTypeSelections?.map((type) => {
                                            return <MenuItem
                                                value={type.value}
                                                key={type.value}>
                                                {type.label}
                                            </MenuItem>
                                        })
                                    }
                                </Select>}
                        />
                        <FormHelperText>Please select a fedrisk document type</FormHelperText>
                    </div>
                )}
                {(projectId && fedriskObjType && fedriskObjType !== "projects" &&
                    <div className="form-field fedriskobjtypeids">
                        <label>Attached to *</label>
                        <Controller
                            name="fedriskObjIdSelect"
                            control={control}
                            defaultValue={0}
                            render={({ field }) =>
                                <Select
                                    {...field}
                                    onChange={handleChangeFedriskObjIdTypeSelect}
                                    value={fedriskObjId ? fedriskObjId : 0}
                                    defaultValue={0}
                                    inputProps={{ "data-testid": "attached" }}
                                >
                                    {
                                        fedriskObjIdSelections?.map((type) => {
                                            return <MenuItem
                                                value={type.id}
                                                key={type.id}>
                                                {type.name}
                                            </MenuItem>
                                        })
                                    }
                                </Select>}
                        />
                        <FormHelperText>Please select a fedrisk document ID</FormHelperText>
                    </div>
                )}
                <div className="form-field file">
                    <label>Upload file</label>
                    <Controller
                        name="projectDocument"
                        control={control}
                        render={({ field }) =>
                            <input
                                data-testid="upInput"
                                {...field}
                                type="file"
                                {...register("fileName",{
                                    validate: {
                                        acceptedFormats: files =>
                                          ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'image/jpeg', 'image/png', 'image/gif'].includes(
                                            files[0]?.type
                                          ) || 'Only document and image file types allowed',
                                      },
                                })}
                                onChange={handleDocumentAsFile}
                            //value={document}
                            />
                        }
                    />
                    <FormHelperText>Only document and image file types allowed</FormHelperText>
                </div>

                <div>
                    {
                        (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
                        (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
                    }
                </div>
            </form>
        </>
    )
}