import React, { useState, useEffect } from 'react';
import './css/App.css';
import SystemAdministration from './dashboards/SystemAdministration';
import Login from './components/Login';
import Header from './components/Header';
import { Box } from "@mui/material";
import Main from './dashboards/Main';
import Notifications from './dashboards/Notifications';
import Tasks from './dashboards/Tasks';
import Governance from './dashboards/Governance';
import Risk from './dashboards/Risk';
import Compliance from './dashboards/Compliance';
import Reporting from './dashboards/Reporting';
import Subscription from './dashboards/Subscription';
import ProjectStudio from './dashboards/ProjectStudio';
import Details from './views/Details';
import ProjectRisk from './views/ProjectRisk';
import ProjectAuditTest from './views/ProjectAuditTest';
import ProjectControl from './views/ProjectControl';
import ProjectControlAssessment from './views/ProjectControlAssessment';
import ProjectControlException from './views/ProjectControlException';
import ProjectTask from './views/ProjectTask';
import ProjectCapPoam from './views/ProjectCapPoam';
import KeywordResults from './views/KeywordResults';
import Register from './components/Register';
import RegisterUserTenant from './components/RegisterUserTenant';
import RegisterSelectPlan from './components/RegisterSelectPlan';
import RegisterPaymentMethod from './components/RegisterPaymentMethod';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordConfirm from './components/ForgotPasswordConfirm';
import TrialMessage from './components/TrialMessage';
import CancelMessage from './components/CancelMessage';
import FrameworkList from './components/lists/FrameworkList';
import FrameworkImportList from './components/lists/FrameworkImportList';
import AWSControlImportList from './components/lists/AWSControlImporList';
import KeywordList from './components/lists/KeywordList';
import ProjectList from './components/lists/ProjectList';
import List from './components/lists/List';
import TaskList from './components/lists/TaskList';
import Profile from './dashboards/Profile';
import Project from './views/Project';
import Framework from './views/Framework';
import FrameworkVersion from './views/FrameworkVersion';
import WBSStudio from './views/WBSStudio';
import HelpSupport from './views/HelpSupport';
import SideNavBar from './components/SideNavBar';
import CircularProgress from '@mui/material/CircularProgress';
import jwt_decode from "jwt-decode";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {
  Routes, Route
} from 'react-router-dom';
import { MenuContext } from './modules/MenuContext';

import FooterComponent from './components/Footer';

import Flowchart from './components/FlowChart';

//import { Helmet } from "react-helmet"

function App() {

  //const containerCode = `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify', 'hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','WEC783631WXID');`;

  //Auth token
  const [token, setToken] = useState();

  //Path
  const [path, setPath] = useState('');

  //Data object id
  const [id, setId] = useState(null);

  const [trialing, setTrialing] = useState(false);

  const [isCanceled, setIsCanceled] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const [isBillingAdmin, setIsBillingAdmin] = useState(false);

  //Sub path
  const [subpath, setSubPath] = useState(null);
  const [subpathId, setSubpathId] = useState(null);
  const [subSubpath, setSubSubPath] = useState(null);
  const [subSubpathId, setSubSubpathId] = useState(null);

  const [loading, setLoading] = useState(false);

  const [clickEvent, setClickEvent, clickEventRef] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const context = {
    clickEvent,
    setClickEvent,
    clickEventRef,
    menuIsOpen,
    setMenuIsOpen,
  };


  useEffect(() => {
    setToken(localStorage.access_token);
  }, [token]);

  useEffect(() => {
    if (token) {
      var decodetoken = jwt_decode(token);
      var expTime = decodetoken.exp * 1000;
      var curTime = Math.floor(Date.now());
      if (expTime < curTime) {
        localStorage.clear();
        setToken(null);
        setLoading(true);
      }
    }
  }, [token])

  useEffect(() => {
    if (localStorage.getItem("system_role") === "5") {
      setIsBillingAdmin(true)
    }
  }, [setIsBillingAdmin])

  useEffect(() => {

  }, [isBillingAdmin])

  useEffect(() => {
    var curpath = window.location.pathname.split("/")[1];

    if (curpath) {
      setPath(curpath);
    } else {
      setPath('/');
    }
    setId(window.location.pathname.split("/")[2]);
    setSubPath(window.location.pathname.split("/")[3]);
    setSubpathId(window.location.pathname.split("/")[4]);
    setSubSubPath(window.location.pathname.split("/")[5]);
    setSubSubpathId(window.location.pathname.split("/")[6]);
  }, [path, subpath, subSubpath]);

  const days = () => {
    setTrialing(true)
    /*if (days === 0 && path !== "subscription") {
      window.location.replace('/subscription')
    }*/
  }

  const canceled = () => {
    setIsCanceled(true)
    isCanceled(true)
  }

  const paused = () => {
    setIsPaused(true)
    isPaused(true)
  }

  useEffect(() => {

  }, [trialing])

  useEffect(() => {
  }, [path])


  if (loading) {
    return (
      <div className="login-wrapper">
        <CircularProgress />
      </div>
    )
  }

  if (!localStorage.access_token && path !== "register" && path !== "register-user" && path !== "forgot-password" && path !== "select-plan" && path !== "payment" && path !== "forgot-password-confirm") {
    return (<Login setToken={setToken} />)
  }

  if (path === "register") {
    return (<Register />)
  }

  if (path === "register-user") {
    return (<RegisterUserTenant />)
  }

  if (path === "select-plan") {
    return (<RegisterSelectPlan />)
  }

  if (path === "payment") {
    return (<RegisterPaymentMethod />)
  }
  if (path === "forgot-password") {

    return (<ForgotPassword />)
  }

  if (path === "forgot-password-confirm") {
    return (<ForgotPasswordConfirm />)
  }

  return (
    <>
      <MenuContext.Provider value={context}>
        {/* }
        <Helmet>
          <script
            src={containerCode}
            crossorigin="anonymous"
            async
          ></script>
        </Helmet>
        { */}

        <Header />
        <div className="App">
          {localStorage.getItem("system_role") !== "5" && localStorage.getItem("is_active") === "Active" ? <><SideNavBar /></> : <></>}
          <main>
            <Box sx={{ display: "flex" }} >
              <div className="wrapper">
                <TrialMessage days={days} />
                {!trialing ? <CancelMessage isCanceled={canceled} isPaused={paused} /> : <></>}
                {localStorage.getItem("is_active") !== "Active" && <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                  <Alert severity="warning">Your account is not activated. Please ask your system administrator to activate your account.</Alert>
                </Stack>}
                {!isBillingAdmin && localStorage.getItem("is_active") === "Active" &&
                  <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/help" element={<HelpSupport />} />
                    <Route path="/system-administration" element={<SystemAdministration />} />
                    <Route path={`/keywords/${id}`} element={<KeywordResults id={id} />} />
                    <Route path="/notifications/all" element={<Notifications />} />
                    <Route path="/notifications/settings" element={<Notifications />} />
                    <Route path="/tasks" element={<Tasks />} />
                    <Route path="/governance" element={<Governance />} />
                    <Route path="/risk" element={<Risk />} />
                    <Route path="/compliance" element={<Compliance />} />
                    <Route path="/reporting" element={<Reporting />} />
                    <Route path="/subscription" element={<Subscription days={trialing} paused={paused} canceled={canceled} />} />
                    <Route path="/project" element={<ProjectStudio />} />
                    <Route path="/frameworks/list" element={<FrameworkList />} />
                    <Route
                      path={`/projects/list`}
                      element={<ProjectList />}
                    />
                    <Route
                      path={`/keyword/list`}
                      element={<KeywordList />}
                    />
                    <Route
                      path={`/framework_import/list`}
                      element={<FrameworkImportList />}
                    />
                    <Route
                      path={`/projects/${id}/controls/aws_control_import/list`}
                      element={<AWSControlImportList projectId={id}/>}
                    />
                    <Route
                      path={`/${path}/list`}
                      element={<List dataType={path} showBreadcrumb={true} />}
                    />
                    <Route path={`/frameworks/${id}`} element={<Framework dataType={path} id={id} />} />
                    <Route
                      path={`/frameworks/${id}/framework_versions/${subpathId}`}
                      element={<FrameworkVersion framework_id={id} framework_version_id={subpathId} />}
                    />
                    <Route
                      path={`/frameworks/${id}/framework_versions/${subpathId}/controls`}
                      element={<List
                        dataType={subSubpath}
                        frameworkId={id}
                        frameworkVersionId={subpathId}
                      />}
                    />
                    <Route
                      path={`/projects/${id}/tasks`}
                      element={<TaskList
                        projectId={id}
                        showBreadcrumb={true}
                      />}
                    />
                    <Route
                      path={`/frameworks/${id}/framework_versions/${subpathId}/controls/${subSubpathId}`}
                      element={
                        <Details
                          dataType={subSubpath}
                          frameworkId={id}
                          frameworkVersionId={subpathId}
                          id={subSubpathId} />}
                    />
                    <Route path={`/controls/${id}`} element={<Details dataType={path} id={id} />} />
                    <Route path={`/projects/${id}`} element={<Project dataType={path} id={id} />} />
                    <Route
                      path={`/projects/${id}/controls`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/risks`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/audit_tests`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/project_evaluations`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/documents`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/users`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/wbs`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/assessments`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/cap_poams`}
                      element={<Project dataType={subpath} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/cap_poams/${subpathId}`}
                      element={<ProjectCapPoam projectId={id} id={subpathId} />}
                    />
                    <Route
                      path={`/projects/${id}/controls/${subpathId}`}
                      element={<ProjectControl projectId={id} id={subpathId} />}
                    />
                    <Route
                      path={`/projects/${id}/controls/${subpathId}/assessments/${subSubpathId}`}
                      element={<ProjectControlAssessment projectId={id} id={subSubpathId} />}
                    />
                    <Route
                      path={`/projects/${id}/controls/${subpathId}/exceptions/${subSubpathId}`}
                      element={<ProjectControlException projectId={id} id={subSubpathId} />}
                    />
                    <Route
                      path={`/projects/${id}/audit_tests/${subpathId}`}
                      element={<ProjectAuditTest projectId={id} id={subpathId} />}
                    />

                    <Route
                      path={`/projects/${id}/documents/${subpathId}`}
                      element={<Details dataType={subpath} projectId={id} id={subpathId} />}
                    />

                    <Route
                      path={`/projects/${id}/risks/${subpathId}`}
                      element={<ProjectRisk dataType={subpath} projectId={id} id={subpathId} />}
                    />

                    <Route
                      path={`/projects/${id}/wbsstudio/${subpathId}`}
                      element={<WBSStudio projectId={id} wbsId={subpathId} />}
                    />
                    <Route
                      path={`/projects/${id}/wbsstudio/${subpathId}/gantt`}
                      element={<WBSStudio projectId={id} wbsId={subpathId} />}
                    />
                    <Route
                      path={`/projects/${id}/wbsstudio/${subpathId}/calendar`}
                      element={<WBSStudio projectId={id} wbsId={subpathId} />}
                    />
                    <Route
                      path={`/documents/${id}`}
                      element={<Details dataType={path} id={id} />}
                    />
                    <Route
                      path={`/project_groups/${id}`}
                      element={<Details dataType={path} id={id} />}
                    />
                    <Route
                      path={`/control_classes/${id}`}
                      element={<Details dataType={path} id={id} />}
                    />
                    <Route
                      path={`/control_families/${id}`}
                      element={<Details dataType={path} id={id} />}
                    />
                    <Route
                      path={`/control_phases/${id}`}
                      element={<Details dataType={path} id={id} />}
                    />
                    <Route
                      path={`/project_groups/${id}`}
                      element={<Details dataType={path} id={id} />}
                    />
                    <Route
                      path={`/projects/${id}/project_evaluations/${subpathId}`}
                      element={<Details dataType={subpath} id={subpathId} />}
                    />
                    <Route
                      path={`/users/${id}`}
                      element={<Profile userid={id} />}
                    />
                    <Route
                      path={`/projects/${id}/tasks/${subpathId}`}
                      element={<ProjectTask dataType={subpath} projectId={id} id={subpathId} />}
                    />
                    <Route path={`/projects/${id}/workflows/${subpathId}`} element={<Flowchart projectId={id} flowchartId={subpathId} />} />
                  </Routes>
                }
                {isBillingAdmin && <Subscription days={trialing} paused={paused} canceled={canceled} />}
              </div>
            </Box>
          </main>
        </div>
        <FooterComponent />
      </MenuContext.Provider>
    </>
  );
}

export default App;