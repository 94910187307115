import React, { useState, useEffect, lazy, Suspense } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEditUser from '../components/CreateEditUser';
import SpinnerComponent from '../components/LoadingSpinner';
import Box from '@mui/material/Box';
import '../css/Profile.css';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ProjectList = lazy(() => import('../components/lists/ProjectList'));

/* This displays a user profile */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Profile({ userid }) {

  const [userData, setUserData] = useState(null);

  const [profileId, setProfileId] = useState();

  const [editOpen, setEditOpen] = useState(false);

  const handleClose = () => {
    setEditOpen(false);
  }

  const EditData = () => {
    setEditOpen(true);
  }

  async function loadData() {

    //call data endpoint for data type to set rows and columns
    var resp;
    var url;
    if (userid) {
      url = `${process.env.REACT_APP_API_URL}/users/${userid}/`;
    }
    if (!userid) {
      url = `${process.env.REACT_APP_API_URL}/users/${localStorage.getItem("userid")}/`;
    }
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(response => {
      resp = response;
      return response.json();
    }).then(json => {
      return {
        response: resp,
        json: json,
        error: !resp.ok
      };
    });

  }

  async function getData() {
    const jsonData = await loadData();
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    if (jsonData.error) {
      //handle the error
      alert(`There was an error ${JSON.stringify(jsonData)}`)
    }
    if (jsonData) {
      setUserData(jsonData.json);
    }
  }

  useEffect(() => {
    if (!userData) {
      getData();
    }

  });

  useEffect(() => {
    if (userid) {
      setProfileId(userid)
    }
    if (!userid) {
      setProfileId(window.location.pathname.split("/")[1])
    }
  }, [userid, profileId])

  return (
    <>
      <div><Button variant="contained" onClick={EditData}>Edit</Button></div>
      <h1>User Profile</h1>
      {(userData?.profile_picture_tags === "Clean" && userData?.profile_picture && <Box component="img" className="profile-pic" sx={{
      }}
        alt="Profile picture."
        src={`${userData.profile_picture_url}`} />)}
      {userData?.profile_picture_tags === "Infected" && <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
        <Alert severity="error">Your user profile picture was scanned and malicious content was found. Please try another file.</Alert>
      </Stack>}
      <p>Email: {userData?.email}</p>
      <p>Phone: +{userData?.phone_no}</p>
      <p>First name: {userData?.first_name}</p>
      <p>Last name: {userData?.last_name}</p>
      <p>System role: {userData?.system_role_name}</p>
      <h2>Associated Projects</h2>
      {profileId ? <Suspense fallback={<SpinnerComponent />}><ProjectList userId={profileId} /></Suspense> : <></>}

      <Dialog
        //fullScreen
        open={editOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={`dialog edit`}
      >
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <DialogTitle>Edit {userData?.email}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CreateEditUser editUserObj={userData} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}